import jwt from '@credlock-x/jwt-node'

import referralObject from './referral'
import scheduledInvoiceObject from './scheduled_invoice'
import invoices from './invoices'
import invoice from './invoice'
import members from './memberships'
import workouts from './workouts'
import freezePolicy from './freeze_policy'
import cancellationPolicy from './cancellation_policy'
import locations from './locations'
import updateLocation from './update-location'
import refundableInvoices from './refundable_invoices'

const operator = {
  operator_id: process.env.REACT_APP_OPERATOR,
  locations: [
  ],
}

const mockMember = (status) => {
  return {
    id: status,
    operator_id: operator.operator_id,
    credentials: {
      username: `${status}@example.com`,
      password: 'cl3',
    },
    token: jwt.pack({
      operator: operator.operator_id,
      user: '814c37d1-10e1-4880-89fb-9e7ed8aa7518',
      permissions: [
        'membership_token', status,
      ],
    }, 'secret'),
  }
}

const mockMembers = [
  mockMember('active'),
  mockMember('cancelled'),
  mockMember('in_freeze'),
  mockMember('in_cancellation'),
  mockMember('in_trial'),
  mockMember('presale'),
  mockMember('cancelled_in_presale'),
  mockMember('cancelled_in_trial'),
  mockMember('used_freeze'),
  mockMember('scheduled_freeze'),
]

const postAuthAuthenticate = (uri, opts) => {
  const body = JSON.parse(opts.body)
  const membership = mockMembers.find(m => m.credentials.username === body.username)

  if (body.operator_id !== operator.operator_id) {
    console.error('wrong operator_id')
    return { ok: false }
  } else if (body.username !== membership.credentials.username) {
    console.error('wrong username')
    return { ok: false }
  } else if (body.password !== membership.credentials.password) {
    console.error('wrong password')
    return {
      ok: false,
      status: 404,
      json: () => Promise.resolve({
        status: 404,
        reason: 'Error: Password check failed',
      }),
    }
  }

  return {
    ok: true,
    json: () => Promise.resolve({
      token: membership.token,
    }),
  }
}

const getMockRes = (res) => {
  return {
    ok: true,
    json: () => Promise.resolve(res),
  }
}

const member = (jwtToken) => {
  try {
    const unpacked = jwt.unpack(jwtToken, 'secret')
    const id = unpacked.permissions[unpacked.permissions.length - 1]
    return members.filter(m => m.id === id)[0]
  } catch (err) {
    localStorage.removeItem('token')
    window.location.replace('/')
  }
}

const deleteFreeze = () => {
  return {
    ok: true,
    json: () => Promise.resolve({
      id: '',
      status: 'active',
      freeze_period: {
        begin_date: '',
        end_date: '',
        cancel_date: '',
      },
    }),
  }
}

const deleteCancellation = () => {
  return {
    ok: true,
    json: () => Promise.resolve({
      id: '',
      status: 'active',
      end_date: null,
    }),
  }
}

const putRFID = (opts) => {
  const code = JSON.parse(opts.body).code

  return {
    ok: true,
    json: () => Promise.resolve({
      type: 'rfid',
      code: code,
      valid_from: '2019-07-29 22:00:00 +0000',
      valid_to: '2039-07-29 22:00:00 +0000',
    }),
  }
}

const updateGDPR = (opts) => {
  return {
    ok: true,
    json: () => Promise.resolve({
      id: '',
      gdpr_opt_in: JSON.parse(opts.body).gdpr_opt_in,
    }),
  }
}

const postFreeze = (opts) => {
  const freezePeriod = JSON.parse(opts.body).freeze_period

  return {
    ok: true,
    json: () => Promise.resolve({
      freeze_period: {
        begin_date: freezePeriod.begin_date,
        end_date: freezePeriod.end_date,
      },
    }),
  }
}

const postCancellation = () => {
  return {
    ok: true,
    json: () => Promise.resolve({
      id: '',
      status: 'in_cancellation',
      end_date: '2018-01-23',
    }),
  }
}

const mockPutRequests = (uri, opts) => {
  switch (uri) {
    case '/v1/membership/gdpr': return updateGDPR(opts)
    case '/v1/membership/rfids': return putRFID(opts)
    default: return { ok: false }
  }
}

const mockPatchRequests = (uri, opts) => {
  return {
    ok: true,
    json: () => Promise.resolve({}),
  }
}

const mockDibsUrl = (uri) => {
  const splitted = uri
    .replace('&', ' ')
    .replace('?', ' ')
    .split(' ')

  // Find the value for cancel param
  const cancelurl = splitted.find((e) => {
    return e.includes('cancel')
  }).split('=')[1]

  // Find the value for success param
  const accepturl = splitted.find((e) => {
    return e.includes('success')
  }).split('=')[1]

  // Server - server callbackurl
  const callbackurl = 'evo'

  // Building dibs url for test dibs account
  return getMockRes({ url: `https://payment.architrade.com/paymentweb/start.action?merchant=90007488&md5key=f52459d5be0a8f7f339824c4da396876&amount=1&callbackurl=${callbackurl}&currency=578&orderid=3d4b81c4-62f0-4106-ade8-4eda6d314d5f&lang=no&context=update&paytype=VISA&cancelurl=${cancelurl}&accepturl=${accepturl}&test=true` })
}

const mockVerifoneUrl = (url) => {
  // checkout ID is most likely expired - can be regnerated with end-to-end prepare checkout spec in billing-verifone
  const checkout = '8ebc09c5-7042-402c-b3a5-7792091ff399'

  return getMockRes({ url: `https://cst.checkout.vficloud.net/v2/loader.js?checkoutId=${checkout}` })
}

const mockGetRequests = (uri, opts) => {
  if (uri.match('dibs_url')) return mockDibsUrl(uri)
  if (uri.match('verifone_url')) return mockVerifoneUrl(uri)

  const membership = member(opts.headers.Authorization)

  const mockObjects = {
    '/v2/membership': membership,
    '/v1/membership/invoices': invoices,
    '/v1/membership/invoices/next': scheduledInvoiceObject,
    '/v1/current_referral': referralObject,
    '/v2/workouts': workouts,
    '/v1/membership/policies/freeze': freezePolicy.filter(p => p.membership.id === membership.id)[0],
    '/v1/membership/policies/cancellation': cancellationPolicy,
    '/v1/membership/invoices/abc': invoice,
    '/v1/membership/invoices/refundable': refundableInvoices,

    '/v1/membership/locations': locations,
  }

  return getMockRes(mockObjects[uri])
}

const successPost = () => {
  return {
    ok: true,
    json: () => Promise.resolve({}),
  }
}

const postResetPassword = (opts) => {
  const body = JSON.parse(opts.body)

  if (body.token === 'invalid') {
    return {
      ok: false,
      status: 422,
      json: () => Promise.resolve({}),
    }
  }

  if (body.username === 'active@example.com' || body.password === 'something') {
    return successPost()
  }

  if (body.username === 'notfound@example.com') {
    return {
      ok: false,
      status: 404,
      json: () => Promise.resolve({}),
    }
  }

  return { ok: false, json: () => Promise.resolve({}) }
}

const mockPostRequests = (uri, opts) => {
  switch (uri) {
    case '/v1/membership/freeze_periods': return postFreeze(opts)
    case '/v1/membership/cancellations': return postCancellation()
    case '/auth/reset': return postResetPassword(opts)
    case '/auth/update': return postResetPassword(opts)
    case '/v1/membership/update-location': return updateLocation()
    case '/v1/membership/invoices/254678fd-0e34-4e84-a4eb-dff14625fd6c/refund': return successPost()
    case '/v1/membership/invoices/61ab0b9a-1a7d-4cdd-90b0-502154ef3a32/manual_refund': return successPost()
    case '/v1/membership/verifone_credit_card': return { ok: true, json: () => Promise.resolve({}) }
    default: return { ok: false, json: () => Promise.resolve({}) }
  }
}

const mockDeleteRequests = (uri) => {
  switch (uri) {
    case '/v1/membership/cancellations': return deleteCancellation()
    case '/v1/membership/freeze_periods/1': return deleteFreeze()
    case '/v1/membership/rfids': {
      return {
        ok: true,
        json: () => Promise.resolve({}),
      }
    }
    default: return { ok: false }
  }
}

const getFetchMock = (uri, opts) => {
  console.info(`mock fetch ${opts.method}: ${uri}`)
  // console.log('auth', opts.headers.Authorization)

  // console.log('opts', opts)
  if (uri === '/auth/authenticate' && opts.method === 'POST') {
    return postAuthAuthenticate(uri, opts)
  }

  switch (opts.method) {
    case 'GET': return mockGetRequests(uri, opts)
    case 'POST': return mockPostRequests(uri, opts)
    case 'PUT': return mockPutRequests(uri, opts)
    case 'PATCH': return mockPatchRequests(uri, opts)
    case 'DELETE': return mockDeleteRequests(uri)
    default: return { ok: false }
  }
}

export default (uri, opts) => {
  return Promise.resolve(getFetchMock(uri, opts))
}
