import React from 'react'
import PropTypes from 'prop-types'
import { StandardPage } from 'components/DefaultPages'

const SuccessPage = ({ history }) => {
  return (
    <StandardPage
      headerI18nKey={ 'pages.profile.success.title' }
      infoI18nKey={ 'pages.profile.success.info' }
      type={ 'success' }
      goBack={ history.goBack }
    />
  )
}

SuccessPage.propTypes = {
  history: PropTypes.object.isRequired,
}

export default SuccessPage
