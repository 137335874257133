import moment from 'moment'

const member = (status, option = '') => {
  let endDate = null
  let beginDate = '2017-01-16'
  let freezePeriods = []
  let activatesOn = null
  let scheduledFreeze = null

  let keys = [
    {
      type: 'rfid',
      code: '123456789',
      status: 'active',
    },
    {
      type: 'pin',
      code: '123456',
      status: 'active',
    },
  ]

  if (status === 'cancelled' || status === 'cancelled_in_presale' || status === 'cancelled_in_trial' || status === 'in_cancellation') {
    endDate = '2018-01-23'
  }

  if (status === 'in_trial' || status === 'presale') {
    beginDate = '2019-11-23'
    keys.shift()
  }

  if (status === 'in_freeze') {
    keys = [
      {
        type: 'rfid',
        code: '123456789',
        status: 'suspended',
      },
      {
        type: 'pin',
        code: '123456',
        status: 'inactive',
      },
    ]

    freezePeriods = [{
      begin_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
    }]
    activatesOn = moment().endOf('month').add(1, 'day').format('YYYY-MM-DD')
  }

  if (option === 'used_freeze') {
    freezePeriods = [{
      begin_date: moment().add(-3, 'months').startOf('month').format('YYYY-MM-DD'),
      end_date: moment().add(-2, 'months').endOf('month').format('YYYY-MM-DD'),
    }]
    activatesOn = null
  } else if (option === 'scheduled_freeze') {
    freezePeriods = [{
      begin_date: moment().add(2, 'months').startOf('month').format('YYYY-MM-DD'),
      end_date: moment().add(3, 'months').endOf('month').format('YYYY-MM-DD'),
    }]
    scheduledFreeze = {
      begin_date: moment().add(2, 'months').startOf('month').format('YYYY-MM-DD'),
      end_date: moment().add(3, 'months').endOf('month').format('YYYY-MM-DD'),
    }
    activatesOn = freezePeriods[0].begin_date
  }

  return {
    id: `${option === '' ? status : option}`,
    membership_number: 143334,
    created_at: '2017-01-16 18:01:56 +0000',
    status: status,
    activates_on: activatesOn,
    begin_date: beginDate,
    end_date: endDate,
    referral_code: 'K03O0',
    gdpr_opt_in: true,
    sheltered: false,
    freeze_periods: freezePeriods,
    scheduled_freeze: scheduledFreeze,
    operator: {
      id: '848c0413-5e75-4f7a-87ad-e45a937c6318',
      name: 'no',
      country: null,
      timezone: null,
      currency: 'NOK',
    },
    profile: {
      gdpr_opt_in: null,
      first_name: 'Peter',
      last_name: 'Holmen',
      gender: 'MALE',
      birthdate: '1990-03-25',
      email: 'vivian@dickensklocko.org',
      address: {
        street: 'Kirkegata 17',
        postal_code: '0382',
        postal_location: 'OSLO',
        country: 'Norway',
      },
      mobile: {
        prefix: '+47',
        number: '34280786',
      },
    },
    location: {
      id: '2ce04e23-361e-4e8e-b73b-55ac3c27b558',
      name: 'EVO Haugesund',
      opening_date: '2013-08-15',
      closing_date: null,
    },
    product: {
      post_signup_presentation: 'Du meldte du deg inn via vårt Google-tilbud som ga deg trening ut februar 2017 for kr. 199,- i tillegg fikk du fri innmeldingsavgift på kr 249,- og 25 min med personlig trener. Hele beløpet blir belastet ved innmelding og står derfor forklart som startavgift. Medlemskapet løper automatisk fra 1. mars 2017 til kr 349,- pr. mnd. Ditt medlemskap er inngått med standard medlemsvilkår, som innebærer 1 mnd. oppsigelsestid.',
      locations: 'all',
      schedule: 'fulltime',
      require_phone_verification: false,
    },
    current_payment_method: {
      id: 'e57aa12d-d90e-4520-9aef-f19b36ed72ce',
      provider: 'dibs',
      type: 'credit_card',
      brand: 'VISA',
      details: '492556******2994',
      expire: '1910',
    },
    keys: keys,
  }
}

const active = member('active')
const inTrial = member('in_trial')
const presale = member('presale')
const cancelled = member('cancelled')
const cancelledInPresale = member('cancelled_in_presale')
const cancelledInTrial = member('cancelled_in_trial')
const inFreeze = member('in_freeze')
const inCancellation = member('in_cancellation')
const usedFreeze = member('active', 'used_freeze')
const scheduledFreeze = member('active', 'scheduled_freeze')

export default [
  active,
  cancelled,
  cancelledInPresale,
  cancelledInTrial,
  inCancellation,
  inFreeze,
  inTrial,
  presale,
  usedFreeze,
  scheduledFreeze,
]

export {
  active,
  cancelled,
  cancelledInPresale,
  cancelledInTrial,
  inCancellation,
  inFreeze,
  inTrial,
  presale,
  usedFreeze,
  scheduledFreeze,
}
