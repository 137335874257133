import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { ActionButton } from 'components/Buttons'
import { objByStringKey } from 'helpers'
import countries from './components/country'

const Divider = () =>
  <div className={'divider'}>&nbsp;</div>

const UpdateProfileView = ({ onChange, onSubmit, profile, validate }) => {
  const { t } = React.useContext(GlobalContext)

  // force emails to be lowercase when submitted
  const onFocusOut = (e) => {
    if (e.target.name === 'email') {
      e.target.value = e.target.value.toLowerCase()
    } else if (e.target.name === 'mobile.number') {
      e.target.value = e.target.value.replaceAll(' ', '')
    } else if (e.target.name === 'mobile.prefix') {
      const validator = findValidator(e.target.value)
      document.getElementsByName('mobile.number')[0].setAttribute('pattern', validator)
    }
  }

  const prefixOptions = countries.map((c) => <option key={c.prefix} value={c.prefix}>{c.prefix}</option>)

  const findValidator = (value) => {
    const country = countries.find((c) => c.prefix === value)
    return country ? country.validator : '^[0-9]{7,12}$'
  }

  const handleInvalid = (event) => {
    const digits = event.target.pattern.split(/[{}]/)?.[1]?.replace(',', ' to ')
    event.target.setCustomValidity(`Number must be ${digits} digits in length`)
  }
  const checkNumber = (event) => {
    if (event.target.value.replaceAll(' ', '').match(event.target.pattern)) {
      event.target.setCustomValidity('')
    } else {
      handleInvalid(event)
    }
  }

  const label = (field, txt = null) =>
    <label className={field}>
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <input
        type={'text'}
        name={field}
        defaultValue={objByStringKey(profile, field)}
        required
        onBlur={onFocusOut}
      />
    </label>

  const inputter = (field, txt = null, dep = null) =>
    <label className="{field} tooltip">
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <input
        type={'text'}
        name={field}
        pattern={findValidator(objByStringKey(profile, dep))}
        defaultValue={objByStringKey(profile, field)}
        onInvalid={handleInvalid}
        onChange={checkNumber}
        required
        onBlur={onFocusOut}
        disabled={validate}
      />
      <span className="tooltiptext"
        dangerouslySetInnerHTML={{ __html: validate ? t('pages.profile.support') : '' }}
      ></span>
    </label>

  const selector = (field, txt = null) =>
    <label className={field}>
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <select
        name={field}
        defaultValue={objByStringKey(profile, field)}
        required
        disabled={validate}
        onBlur={onFocusOut}
      >{prefixOptions}</select>
    </label>

  console.log('profile view:', profile, validate)
  return (
    <div id={'profile'}>
      <h1>{ t('pages.profile.title') }</h1>
      <div className={'full-width block'}>
        <form onChange={onChange} onSubmit={onSubmit}>
          <div className={'form-grid'}>
            { label('address.street') }
            { label('address.postal_code') }
            <Divider />
            { label('address.postal_location') }
            { selector('mobile.prefix', t('forms.labels.country')) }
            <Divider />
            { inputter('mobile.number', t('forms.labels.mobile'), 'mobile.prefix') }
            { label('email') }
          </div>
          <ActionButton type={'submit'}>
            { t('pages.profile.button') }
          </ActionButton>
        </form>
      </div>
    </div>
  )
}

UpdateProfileView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  validate: PropTypes.bool,
}

export default UpdateProfileView
