export default {
  amount: 1470,
  created_at: '2016-09-21T22:00:09.000Z',
  currency: 'EUR',
  id: '4b0db409-8095-4e3a-9bd2-14119a11d95e',
  invoice_date: '2016-09-22',
  lines: [
    {
      amount: 1470,
      product_type: 'tbd',
      product_id: 'e44e06a0-87e8-4175-8ab6-0435f7fddb40',
      system_concept: 'base_service_fee',
      human_concept: 'tbd',
      period: {
        from: '2016-09-22',
        to: '2016-09-30',
      },
    },
  ],
  invoice_number: 55,
  payments: [
    {
      payment_method_id: 'b2bef235-fd78-4977-8f12-6c624fe930a3',
      amount: 1470,
      status: 'accepted',
      currency: 'EUR',
      created_at: '2016-09-22T00:00:09.000Z',
      updated_at: '2016-09-22T00:00:09.000Z',
      provider_details: {
        transaction_id: '1484199752',
        action_code: null,
        error_code: null,
        merchant: 'tbd',
      },
      refunds: [],
      id: '9aa39274-bf8f-477a-83f9-904e68f7c4fa',
    },
  ],
  period: {
    from: '2016-09-22',
    to: '2016-09-30',
  },
  reference: '21b9701e-d6b0-4a31-a6ec-535b850c3864',
  retry: true,
  status: 'charged',
  stopped: true,
  updated_at: '2016-09-21T22:00:09.000Z',
  user_id: '21b9701e-d6b0-4a31-a6ec-535b850c3864',
  payment_method: {
    id: 'b2bef235-fd78-4977-8f12-6c624fe930a3',
    provider: 'dibs',
    type: 'credit_card',
    brand: 'VISA',
    details: '490473******6310',
    expire: '2003',
  },
}
