import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/icons'
import { statusIsInactive } from 'helpers'
import { Link } from 'react-router-dom'

const CardDetails = ({ cc }) =>
  <p>
    { cc.details }
    <Icon
      type={cc.brand.toLowerCase()}
      style={{
        marginLeft: '8px',
        fontSize: '24px',
        marginBottom: '-5px',
      }}
    />
  </p>

CardDetails.propTypes = {
  cc: PropTypes.object.isRequired,
}

const CardExpiration = ({ cc, t }) => {
  const expirationDate = (pm) =>
    pm.expire.slice(2, 4) + '/20' + pm.expire.slice(0, 2)

  return (
    <p>
      { t('pages.payment.credit_card.expires') } { expirationDate(cc) }
    </p>
  )
}

CardExpiration.propTypes = {
  cc: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const RedirectUrl = ({ locale, t, location }) => {
  return (
    <Link to='/payment/credit-card'>
      { t('pages.payment.credit_card.update_link') }
    </Link>
  )
}

RedirectUrl.propTypes = {
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  existingProvider: PropTypes.string.isRequired,
}

const CreditCardBox = ({ membership, t }) => {
  if (statusIsInactive(membership.status)) return null

  // Hide credit card box (details w/update link) if the membership is registered with another payment methond which is _not_ credit card
  if (membership.current_payment_method && membership.current_payment_method.type !== 'credit_card') return null

  const { current_payment_method: creditCard } = membership

  return (
    <div className={'full-width block'}>
      <h2>{ t('pages.payment.credit_card.title') }</h2>
      {creditCard && (
        <>
          <CardDetails
            cc={creditCard}
          />
          <CardExpiration
            cc={creditCard}
            t={t}
          />
        </>
      )}
      <RedirectUrl location={membership.location.id} locale={membership.locale} t={t} />
    </div>
  )
}

CreditCardBox.propTypes = {
  membership: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default CreditCardBox
