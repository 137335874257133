import moment from 'moment'

const policy = (selector) => {
  let currentFreeze = null
  let scheduledFreeze = null
  let eligible = true
  let status = 'active'
  const maxMonths = 6
  let available = 6
  let used = 0

  if (selector === 'in_freeze') {
    currentFreeze = {
      begin_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
      cancel_date: null,
    }
    eligible = false
    available = 2
    used = 4
    status = selector
  } else if (selector === 'used_freeze') {
    available = 0
    used = 6
  } else if (selector === 'scheduled_freeze') {
    scheduledFreeze = {
      begin_date: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end_date: moment().add(2, 'month').endOf('month').format('YYYY-MM-DD'),
      cancel_date: null,
    }
  }

  return {
    monthly_fee: 4900,
    max_months: maxMonths,
    required_membership_status: ['active'],
    membership: {
      id: `${selector === status ? status : selector}`,
      status: status,
      available_months: available,
      used_months: used,
      scheduled_freeze: scheduledFreeze,
      eligible_to_freeze: eligible,
      current_freeze: currentFreeze,
    },
  }
}

const active = policy('active')
const inFreeze = policy('in_freeze')
const usedFreeze = policy('used_freeze')
const scheduledFreeze = policy('scheduled_freeze')

export default [
  active,
  inFreeze,
  usedFreeze,
  scheduledFreeze,
]
